.scroll-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
}

.scroll-style::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.scroll-style::-webkit-scrollbar-thumb {
  background-color: #535353;
  border: 1px solid #555555;
  border-radius: 20px;
}

.popup-menu-custom {
  max-height: 400px;
  overflow: auto;
}

th.column-money,
td.column-money {
  text-align: right !important;
  margin-right: 30px;
}
th.column-text-center,
td.column-text-center {
  text-align: center !important;
}
@-webkit-keyframes invalid {
  from {
    background-color: red;
  }
  to {
    background-color: inherit;
  }
}
@-moz-keyframes invalid {
  from {
    background-color: red;
  }
  to {
    background-color: inherit;
  }
}
@-o-keyframes invalid {
  from {
    background-color: red;
  }
  to {
    background-color: inherit;
  }
}
@keyframes invalid {
  from {
    background-color: red;
  }
  to {
    background-color: inherit;
  }
}
.invalid {
  -webkit-animation: invalid 1s infinite; /* Safari 4+ */
  -moz-animation: invalid 1s infinite; /* Fx 5+ */
  -o-animation: invalid 1s infinite; /* Opera 12+ */
  animation: invalid 1s infinite; /* IE 10+ */
}

/** custom antd **/
.ant-card {
  line-height: 1.3 !important;
}
.ant-card-meta-title {
  font-size: 13px !important;
}
.ant-card-body {
  padding: 10px 8px 10px 8px !important;
}
.ant-card-actions {
  line-height: 0 !important;
}
.ant-card-actions li {
  height: 0.3px !important;
}
.ant-card-actions li span {
  line-height: 0 !important;
  font-size: 13px !important;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;

  /*optional*/
  font-family: arial, sans-serif; /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.ant-tabs-nav .ant-tabs-tab-active {
  display: none;
}
.antd-pro-notice-icon-list
  .antd-pro-notice-icon-item
  .antd-pro-notice-icon-iconElement {
  font-size: 22px;
}

.antd-pro-notice-icon-list
  .antd-pro-notice-icon-item
  .antd-pro-notice-icon-title {
  font-size: 12px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.antd-pro-notice-icon-datetime {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.ant-table-thead {
  font-size: 11px;
}

.ant-list-item {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.antd-pro-notice-icon-list .antd-pro-notice-icon-item {
  padding: 0px 10px 0px 10px;
}

.ant-form-item-label {
  line-height: 25px !important;
  padding: 12px 0px 0px 0px !important;
}

.highlight-row {
  background-color: rgba(179, 224, 255, 0.3);
}

.highlight-row-dark {
  background-color: rgba(179, 224, 255, 0.6);
}

.bg-gradient {
  background: linear-gradient(269deg, #397aff, #6288ff, #b4cdff);
  background-size: 600% 600%;

  -webkit-animation: RowBGGradient 10s ease infinite;
  -moz-animation: RowBGGradient 10s ease infinite;
  animation: RowBGGradient 10s ease infinite;
}

@-webkit-keyframes RowBGGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes RowBGGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes RowBGGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.menu-item-wrap {
  white-space: normal !important;
  line-height: 20px !important;
  height: 100% !important;
  overflow: visible !important;
  display: inline-block !important;
}

/* .modal-driver-card:hover {
  opacity: 0.2;
} */

@media only screen and (max-width: 576px) {
  .common-drawer {
    width: 80%;
  }
}

.alert-alarm-table {
  margin: 0px 0px 10px 0px !important;
}

/* .alert-alarm-table div table thead{
  background-color: #dededebf !important;
}

.alert-alarm-table div table thead tr th{
  border-style: none !important;
} */

.alert-alarm-table div table tbody tr td {
  padding: 1px 2px !important;
}

.tracking-table {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.tracking-table div table thead tr th {
  font-size: 9px;
}

.tracking-table div table tbody tr td {
  font-size: 12px;
  padding: 1px 4px !important;
}

.tracking-detail-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 12px;
}

.tracking-detail-table > tbody > tr > th {
  width: 135px;
}

.tracking-detail-table {
  border-left: solid #40a9ff 0.5px;
}

.edge-btn-group button {
  line-height: 1.499;

  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;

  font-size: 14px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;

  padding: 8px 15px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  font-weight: 400;
  text-align: center;
  display: block; /* Make the buttons appear below each other */
}

.edge-btn-group button:not(:last-child) {
  /* Prevent double borders */
  /* border-bottom: 0; */
}

/* .edge-btn-group button:hover {
  background: #1890ff;
  color: #fff;
} */

/* Add a background color on hover */
.edge-btn-group button:hover,
.edge-btn-group button:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}

.edge-element {
  /* border-radius: 0 !important; */
}

.table-sign-4 {
  width: 30px;
  height: 30px;
}

.content-bold {
  color: #fd6e00;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
}

.circleBase {
  border-radius: 50%;
}

.yellow-dot-marker {
  width: 6px;
  height: 6px;
  background: #ffd619;
  border: 0.2px solid black;
}
.red-dot-marker {
  width: 6px;
  height: 6px;
  background: #c91c1c;
  border: 0.2px solid black;
}
.type3 {
  width: 500px;
  height: 500px;
  background: aqua;
  border: 30px solid blue;
}

.tool-btn-active {
  color: #40a9ff !important;
  background-color: #fff !important;
  border-color: #40a9ff !important;
}

.infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
}

.overlay {
  display: block;
  top: 0px;
  left: 0px;
  opacity: 0.2;
}

.dashcamera-cover-icon {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: grey;
}

.dashcamera-card {
  position: relative;
  text-align: center;
}

.overlay:hover {
  opacity: 1;
}

.dashcamera-card:hover .overlay {
  opacity: 1;
}

/* ///////////////////////////// test for replay ///////////////////////////////// */
.replay-info-box {
  padding: 15px 5px 5px 20px;
}
.replay-monitor {
  position: absolute;
  top: 5px;
  left: 5px;
}
.replay-speed-gauge {
  margin-left: 230px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.monitor {
  /* width: 278px;
  height: 220px; */
  overflow: none;
  border: solid 1em rgb(247, 247, 247);
  border-radius: 0.2em;
}
.monitor::-webkit-scrollbar {
  width: 15px;
}

/* .stand:before {
  content: "";
  display: block;
  position: relative;
  background: rgb(214, 214, 214);
  width: 110px;
  height: 50px;
  top: 270px;
  left: 90px;
} */
/* .stand:after {
  content: "";
  display: block;
  position: relative;
  background: #333;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  width: 300px;
  height: 15px;
  top: 50px;
  left: 110px;
} */

.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}

.custom-tooltip {
  line-height: 0.6;
  /* background-color: white; */
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.custom-tooltip-body {
  font-size: 13px;
  margin: 5px 5px 5px 5px;
}
